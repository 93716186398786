import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import digitalTwin from '../assets/images/digital-twin.png';
import filipe_silva from '../assets/images/filipe_silva.jpeg';

import layoutStyle from '../styles/layout';
import { media, colors } from '../styles/constants';

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  margin-top: 3.75rem;

  .fake-header-wrapper {
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      font-size: 1.8rem;
      width: 950px;
      padding-bottom: 2rem;
      line-height: 1;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    ${layoutStyle}

    h2 {
      text-align: center;
      font-size: 1.8rem;
      padding-bottom: 2rem;

      @media (min-width: ${media.phoneLandscape}px) {
        font-size: 2.5rem;
        align-self: center;
      }

      @media (min-width: ${media.newsTextWidth}px) {
        width: 950px;
      }
    }
    h3 {
      font-size: 1.6rem;
    }
  }
`;

const HowItWorksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media (min-width: ${media.newsTextWidth}px) {
    width: 950px;
  }

  .image-midsection {
    @media (min-width: ${media.newsTextWidth}px) {
      display: flex;
      div {
        width: 100%;
      }
      img {
        width: 80%;
      }
    }
    @supports not (-moz-appearance: none) {
      @media (min-width: ${media.newsTextWidth}px) {
        img {
          width: 45%;
        }
      }
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    font-size: 3rem;
    top: 0;
    left: -2.75rem;
    color: ${colors.blue};
    display: none;

    @media (min-width: ${media.phonePortrait}px) {
      display: inherit;
    }
  }

  p {
    margin-bottom: 2rem;
    line-height: 1.8;
  }
`;

const Signature = styled.div`
  display: flex;
  justify-content: end;
  text-align: right;
  img {
    width: 40px !important;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;

  img {
    width: 100%;
    max-width: 950px;

    @media (min-width: ${media.navMaxLength}px) {
      width: 950px;
    }

    margin-bottom: 2rem;
    align-self: center;
  }

  ${TextContainer} {
    font-size: 1.2rem;
    line-height: 1.8;
    flex: 1 1 100%;
    padding-bottom: 2rem;
    align-self: center;
  }

  .gatsby-image-wrapper {
    width: 50%;
    margin: 2rem auto;
  }

  @media (min-width: ${media.tabletPortrait}px) {
    align-items: center;
    flex-direction: column-reverse;

    ${TextContainer} {
      font-size: 1.2rem;
      line-height: 1.8;
      flex: 1 0 55%;
      padding-bottom: 0;
    }

    .gatsby-image-wrapper {
      flex: 0 1 45%;
    }

    &:nth-child(2) {
      flex-direction: row-reverse;
    }
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
  }

  &:not(:last-child) {
    padding-bottom: 1.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      padding-bottom: 2.5rem;
    }
  }

  :last-child {
    .gatsby-image-wrapper picture {
      max-width: 90%;
      max-height: 90%;
    }
  }

  :first-child ${TextContainer}:before {
    left: -2rem;
    color: ${colors.blue};
  }
`;

function NewsPageComponent({ backgroundColor = colors.lightGrey }) {
  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      <div>
        <div className="fake-header-wrapper">
          <p>
            <strong>
                The Challenge of Monitoring Internal Manufacturing Processes
            </strong>
          </p>
        </div>

        <HowItWorksContainer>
          <NewsWrapper>
            <TextContainer>
            <div class="article">
                <p>
                In the complex environment of manufacturing, the need for efficient, accurate, and <strong>timely tracking of internal processes is critical.</strong> Manufacturing facilities handle countless moving parts, from production line machinery to assets that travel through various stages of production.<strong> Manual tracking methods often fall short</strong> with these processes moving quickly and constantly evolving. As companies strive for greater efficiency, reduced downtime, and increased responsiveness to market demands,<strong> process visibility within their facilities has become a fundamental challenge.</strong>
                </p>
                <h3>The Complexity of Manual Monitoring</h3>
                <p>
                Monitoring internal assets in a manufacturing facility is usually a nightmare. Facilities must track the location, usage, and condition of assets such as tools, machinery, and materials that are frequently relocated throughout the day. Businesses often rely on manual methods to record this movement, whether through paperwork, whiteboards, spreadsheets, or even verbal updates. This approach is <strong>time-consuming and error-prone, leading to delays, inaccuracies, and a lack of real-time visibility</strong> in their daily operations.
                </p>
                <p>
                Manual tracking can also hinder decision-making. Without instant access to the latest information on process flows, it&#39;s difficult for managers to <strong>identify inefficiencies, bottlenecks, or underperforming equipment.</strong> The lack of actionable data not only impacts productivity but also <strong>reduces the ability to respond proactively to potential issues</strong>, ultimately affecting the bottom line.
                </p>
                <h3>How Tracking Technology and Digital Twins Transform Process Management</h3>
                <p>
                Modern tracking technology offers a powerful solution to these challenges. <strong>Automated tracking systems</strong> allow manufacturers to<strong> monitor asset movements, optimize workflows, and gain real-time insights into operations.</strong> These systems collect detailed data at each stage of the production process, identifying inefficiencies, streamlining operations, and ensuring assets are used effectively.
                </p>
                <p>
                Incorporating <strong>Digital Twin</strong> technology takes these benefits even further, enabling manufacturers to create a virtual replica of their factory or warehouse, <strong>that reflects the real-time status of processes, and the location of equipment or assets.</strong> By visualizing the facility in a digital format, companies can simulate potential changes and make<strong> informed decisions in real-time, ultimately creating a more responsive and efficient operation.</strong>
                </p>
                <h3>Azitek&#39;s Role in Improving Manufacturing Process Monitoring</h3>
                <p>
                Azitek&#39;s process monitoring solution is designed to meet the unique challenges of manufacturing environments, offering real-time insights and visibility into internal processes with a system that stands out from traditional tracking technologies. Unlike others, <strong>our solution is highly resilient to metal interference</strong>, ensuring reliable performance even in industrial settings with heavy metal presence. Additionally, our <strong>infrastructure is simple, adaptable, and easy to set up, manage, and change,</strong> making it a straightforward choice for busy operations or changing environments. With Azitek, <strong>you can track assets as they move through different stages, capture data, and integrate it into your existing management systems.</strong> This enables precise monitoring of production line timings, asset locations, and flow, giving <strong>companies unparalleled control in fast-paced, dynamic environments.</strong>
                </p>
                </div>

              <Signature>
                <img src={filipe_silva} />
                <p>
                  <strong>
                    Filipe Silva, Business Developer
                    <br /> Nov 2024
                  </strong>
                </p>
              </Signature>
            </TextContainer>
            <img
              className="origin-story-img"
              src={digitalTwin}
              imgStyle={{ height: 'auto' }}
            />
          </NewsWrapper>
        </HowItWorksContainer>
      </div>
    </BackgroundContainer>
  );
}

NewsPageComponent.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default NewsPageComponent;
